import { Ashtray, Balls, Balusters, CandyBoxes, Cobblestones, Countertops, Crosses, Curbs, CuttingBoards, FacadeTiles, Gabions, Lampad, NapkinHolder, PavingTiles, Railings, SinksBathtubs, Slabs, SoccerBalls, Spheres, Stairs, Urns, Vases, WindowSills } from "./logic/const/constants";

export function initializePrice(subCategory) {
    switch (subCategory) {
        case Cobblestones: return " грн/м²";
        case FacadeTiles: return " грн/м²";
        case PavingTiles: return " грн/м²";
        case Slabs: return " грн/м²";
        case WindowSills: return " грн/пог.м";
        case Countertops: return " грн/пог.м";
        case Balusters: return " грн/шт";
        case Vases: return " грн/шт";
        case Balls: return " грн/шт";
        case Spheres: return " грн/шт";
        case Railings: return " грн/пог.м";
        case Lampad: return " грн/шт";
        case Ashtray: return " грн/шт";
        case NapkinHolder: return " грн/шт";
        case CuttingBoards: return " грн/шт";
        case CandyBoxes: return " грн/шт";
        case SoccerBalls: return " грн/шт";
        case Urns: return " грн/шт";
        case Crosses: return " грн/шт";
        case Gabions: return " грн/пог.м";
        case SinksBathtubs: return " грн/шт";
        case Stairs: return " грн/м²";
        case Curbs: return " грн/шт";
        default: return " грн/комплект"
    }
}