import React from 'react';
import './style.scss';

const CommonLayout = (props) => {

    const background = props.background ? props.background : "#181819";

    return (
        <>
            <div className="layout-background" style={{ background: background }}></div>
            <div className="content" >
                {props.children}
            </div>
        </>
    )
}

export default CommonLayout;