import { Ashtray, Balls, Balusters, Boulders, CandyBoxes, Cobblestones, Countertops, CrushedStone, Curbs, CuttingBoards, FacadeTiles, Fenc, Fountains, Gabions, Lampad, Monuments, PavingTiles, Railings, SinksBathtubs, Slabs, SoccerBalls, Stairs, Vases, WindowSills } from "../logic/const/constants";

export const GalleryProducts = [
    {
        subCategory: Balusters,
        images: [
            "images/балясини (1).webp",
            "images/балясини (2).webp",
            "images/балясини (3).webp",
            "images/балясини (4).webp",
            "images/балясини (5).webp",
            "images/балясини (6).webp",
            "images/балясини (7).webp",
            "images/балясини (8).webp",
            "images/балясини (9).webp",
            "images/балясини (10).webp",
            "images/балясини (11).webp",
            "images/балясини (12).webp",            
            "images/балясини (13).webp",
            "images/балясини (14).webp",
            "images/балясини.webp",
        ]
    },
    {
        subCategory: Curbs,
        images: [
            "images/бордюр (1).webp",
            "images/бордюр (2).webp",
            "images/бордюр (3).webp",
            "images/бордюр (4).webp",
            "images/бордюр (5).webp",
            "images/бордюр (6).webp",
            "images/бордюр (7).webp",
            "images/бордюр (8).webp",
            "images/бордюр (9).webp",
            "images/бордюр.webp",
        ]
    },
    {
        subCategory: Gabions,
        images: [
            "images/габіони (1).webp",
            "images/габіони (2).webp",
            "images/габіони (3).webp",
            "images/габіони (4).webp",
            "images/габіони (5).webp",
            "images/габіони (6).webp",
            "images/габіони (7).webp",
            "images/габіони (8).webp",
            "images/габіони (9).webp",
            "images/габіони (10).webp",
            "images/габіони (11).webp",
            "images/габіони (12).webp",
            "images/габіони.webp",
        ]
    }
    ,
    {
        subCategory: Fenc,
        images: [
            "images/забори (1).webp",
            "images/забори (2).webp",
            "images/забори (3).webp",
            "images/забори (4).webp",
            "images/забори (5).webp",
            "images/забори (6).webp",
            "images/забори (7).webp",
            "images/забори (8).webp",
            "images/забори (9).webp",
            "images/забори (10).webp",
            "images/забори (11).webp",
            "images/забори (12).webp",
            "images/забори (13).webp",
            "images/забори.webp",
        ]
    },
    {
        subCategory: CrushedStone,
        images: [
            "images/бут (1).webp",
            "images/бут (2).webp",
            "images/бут (3).webp",
            "images/бут (4).webp",
            "images/бут (5).webp",
            "images/бут (6).webp",
            "images/бут (7).webp",
            "images/бут (8).webp",
            "images/бут (9).webp",
            "images/бут (10).webp",
            "images/бут.webp",
        ]
    }
    ,
    {
        subCategory: Cobblestones,
        images: [
            "images/бруківка (1).webp",
            "images/бруківка (2).webp",
            "images/бруківка (5).webp",
            "images/бруківка (6).webp",
            "images/бруківка (7).webp",
            "images/бруківка (8).webp",
            "images/бруківка (9).webp",
            "images/бруківка (10).webp",
            "images/бруківка (11).webp",
            "images/бруківка (12).webp",
            "images/бруківка (13).webp",
            "images/бруківка (14).webp",
            "images/бруківка (15).webp",
            "images/бруківка (16).webp",
            "images/бруківка.webp",
        ]
    },
    {
        subCategory: Fountains,
        images: [
            "images/фонтани (1).webp",
            "images/фонтани (2).webp",
            "images/фонтани (3).webp",
            "images/фонтани (4).webp",
            "images/фонтани (5).webp",
            "images/фонтани (6).webp",
            "images/фонтани (7).webp",
            "images/фонтани (8).webp",
            "images/фонтани (9).webp",
            "images/фонтани (10).webp",
            "images/фонтани (11).webp",
            "images/фонтани (12).webp",
            "images/фонтани (13).webp",
            "images/фонтани (14).webp",
            "images/фонтани (15).webp",
            "images/фонтани.webp",
        ]
    },
    {
        subCategory: Countertops,
        images: [
            "images/стільниці (1).webp",
            "images/стільниці (2).webp",
            "images/стільниці (3).webp",
            "images/стільниці (4).webp",
            "images/стільниці (5).webp",
            "images/стільниці (6).webp",
            "images/стільниці (7).webp",
            "images/стільниці (8).webp",
            "images/стільниці (9).webp",
            "images/стільниці (10).webp",
            "images/стільниці (11).webp",
            "images/стільниці (12).webp",
            "images/стільниці (13).webp",
            "images/стільниці (14).webp",
            "images/стільниці.webp",
        ]
    },
    {
        subCategory: Countertops,
        images: [
            "images/стільниці (1).webp",
            "images/стільниці (2).webp",
            "images/стільниці (3).webp",
            "images/стільниці (4).webp",
            "images/стільниці (5).webp",
            "images/стільниці (6).webp",
            "images/стільниці (7).webp",
            "images/стільниці (8).webp",
            "images/стільниці (9).webp",
            "images/стільниці (10).webp",
            "images/стільниці (11).webp",
            "images/стільниці (12).webp",
            "images/стільниці (13).webp",
            "images/стільниці (14).webp",
            "images/стільниці.webp",
        ]
    },
    {
        subCategory: SoccerBalls,
        images: [
            "images/2022-12-17_09-21-06-108.webp",
            "images/10659_800x600_mjach.webp",
            "images/image-kyQrWAT4F-transformed.webp",
            "images/image-Qa7PDJXd8-transformed.webp",
            "images/maxresdefault.webp",
            "images/shary.webp"
        ]
    },
    {
        subCategory: Slabs,
        images: [
            "images/сляби (1).webp",
            "images/сляби (2).webp",
            "images/сляби (3).webp",
            "images/сляби (4).webp",
            "images/сляби (5).webp",
            "images/сляби (6).webp",
            "images/сляби (7).webp",
            "images/сляби (8).webp",
            "images/сляби (9).webp",
            "images/сляби (10).webp",
            "images/сляби (11).webp",
            "images/сляби (12).webp",
            "images/сляби (13).webp",
            "images/сляби (14).webp",
            "images/сляби (15).webp",
            "images/сляби (16).webp",
            "images/сляби (17).webp",
            "images/сляби.webp",
        ]
    },
    {
        subCategory: Ashtray,
        images: [
            "images/попільнички (1).webp",
            "images/попільнички (2).webp",
            "images/попільнички (3).webp",
            "images/попільнички (4).webp",
            "images/попільнички (5).webp",
            "images/попільнички (6).webp",
            "images/попільнички (7).webp",
            "images/попільнички (8).webp",
            "images/попільнички (9).webp",
            "images/попільнички.webp",
        ]
    },
    {
        subCategory: FacadeTiles,
        images: [
            "images/плити скелі (1).webp",
            "images/плити скелі (2).webp",
            "images/плити скелі (3).webp",
            "images/плити скелі (4).webp",
            "images/плити скелі (5).webp",
            "images/плити скелі (6).webp",
            "images/плити скелі (7).webp",
            "images/плити скелі.webp",
        ]
    },
    {
        subCategory: WindowSills,
        images: [
            "images/підвіконня (1).webp",
            "images/підвіконня (2).webp",
            "images/підвіконня (3).webp",
            "images/підвіконня (4).webp",
            "images/підвіконня (5).webp",
            "images/підвіконня (6).webp",
            "images/підвіконня (7).webp",
            "images/підвіконня (8).webp",
            "images/підвіконня (9).webp",
            "images/підвіконня.webp",
        ]
    },
    {
        subCategory: Railings,
        images: [
            "images/перила1 (1).webp",
            "images/перила1 (2).webp",
            "images/перила1 (3).webp",
            "images/перила1 (4).webp",
            "images/перила1 (5).webp",
            "images/перила1 (6).webp",
            "images/перила1 (7).webp",
            "images/перила1 (8).webp",
            "images/перила1 (9).webp",
            "images/перила1 (10).webp",
            "images/перила1 (11).webp",
            "images/перила1 (12).webp",
            "images/перила1.webp",
        ]
    },
    {
        subCategory: Lampad,
        images: [
            "images/лампадки (1).webp",
            "images/лампадки.webp",
        ]
    },
    {
        subCategory: SinksBathtubs,
        images: [
            "images/ванна3.webp",
            "images/ванна5.webp",
            "images/ванна6.webp",
            "images/ванна7.webp",
            "images/ванна8.webp",
            "images/ванна9.webp",
            "images/ванна11.webp",
            "images/ванна13.webp",
        ]
    },
    {
        subCategory: Vases,
        images: [
            "images/vasgallery2.webp",
            "images/vasgallery3.webp",
            "images/vasgallery4.webp",
            "images/vasgallery5.webp",
            "images/vasgallery6.webp",
            "images/vasgallery7.webp",
            "images/vasgallery8.webp",
            "images/vasgallery9.webp",
            "images/vasgallery10.webp",
            "images/vasgallery11.webp",
            "images/vasgallery12.webp",
            "images/vasgallery13.webp",
            "images/vasgallery14.webp",
            "images/vasgallery15.webp",
            "images/vasgallery16.webp",
            "images/vasgallery17.webp",
        ]
    },
    {
        subCategory: Boulders,
        images: [
            "images/валуни1.webp",
            "images/валуни3.webp",
            "images/валуни7.webp",
            "images/валуни8.webp",
            "images/валуни9.webp",
            "images/валуни10.webp",
        ]
    },
    {
        subCategory: Stairs,
        images: [
            "images/сходиг1.webp",
            "images/сходиг2.webp",
            "images/сходиг4.webp",
            "images/сходиг5.webp",
            "images/сходиг6.webp",
            "images/сходиг7.webp",
            "images/сходиг8.webp",
            "images/сходиг10.webp",
            "images/сходиг12.webp",
            "images/сходиг13.webp",
            "images/сходиг14.webp",
            "images/сходиг15.webp",
            "images/сходиг16.webp",
            "images/сходиг17.webp",
        ]
    },
    {
        subCategory: Balls,
        images: [
            "images/куліг1.webp",
            "images/куліг2.webp",
            "images/куліг4.webp",
            "images/куліг6.webp",
            "images/куліг7.webp",
            "images/куліг10.webp"
        ]
    },
    {
        subCategory: CuttingBoards,
        images: [
            "images/дошкиг1.webp",
            "images/дошкиг2.webp",
            "images/дошкиг3.webp",
            "images/дошкиг4.webp",
            "images/дошкиг5.webp",
            "images/дошкиг6.webp",
            "images/дошкиг7.webp",
            "images/дошкиг8.webp",
            "images/дошкиг9.webp",
            "images/дошкиг10.webp",
            "images/дошкиг11.webp",
            "images/дошкиг12.webp",
        ]
    },
    {
        subCategory: CandyBoxes,
        images: [
            "images/цукерниціг1 (1).webp",
            "images/цукерниціг1 (2).webp",
            "images/цукерниціг1 (3).webp",
            "images/цукерниціг1 (4).webp",
            "images/цукерниціг1 (5).webp",
            "images/цукерниціг1 (6).webp",
            "images/цукерниціг1 (7).webp",
            "images/цукерниціг1 (8).webp",
            "images/цукерниціг1 (1).webp",
            "images/цукерниціг1 (1).webp",
            "images/цукерниціг1 (1).webp",
            "images/цукерниціг1 (1).webp",
        ]
    },
    {
        subCategory: PavingTiles,
        images: [
            "images/плиткамощенняг (1).webp",
            "images/плиткамощенняг (2).webp",
            "images/плиткамощенняг (3).webp",
            "images/плиткамощенняг (4).webp",
            "images/плиткамощенняг (5).webp",
            "images/плиткамощенняг (6).webp",
            "images/плиткамощенняг (8).webp",
            "images/плиткамощенняг (9).webp",
            "images/плиткамощенняг (14).webp",
            "images/плиткамощенняг (15).webp",
            "images/плиткамощенняг (16).webp",
            "images/плиткамощенняг (17).webp",
            "images/плиткамощенняг (18).webp",
            "images/плиткамощенняг (19).webp",
            "images/плиткамощенняг (20).webp",
            "images/плиткамощенняг (21).webp",
            "images/плиткамощенняг (22).webp",
            "images/плиткамощенняг (23).webp",
            "images/плиткамощенняг (24).webp",
            "images/плиткамощенняг (25).webp",
            "images/плиткамощенняг (26).webp",
            "images/плиткамощенняг (27).webp",
            "images/плиткамощенняг (28).webp",
            "images/плиткамощенняг (29).webp",
            "images/плиткамощенняг (30).webp",
            "images/плиткамощенняг (31).webp",
            "images/плиткамощенняг (32).webp",
            "images/плиткамощенняг (33).webp",
            "images/плиткамощенняг (34).webp",
            "images/плиткамощенняг (35).webp",
            "images/плиткамощенняг (36).webp",
            "images/плиткамощенняг (37).webp",
            "images/плиткамощенняг (38).webp",
            "images/плиткамощенняг (39).webp",
            "images/плиткамощенняг (40).webp",
        ]
    },
]