import React, { useEffect } from 'react';
import './style.scss';
import CommonLayout from '../../components/Common/Layout/layout';

const TypeOfStone = ({ cartData, setCartData }) => {

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, [])
    return (
        <>
            <CommonLayout background={'white'}>
                <div className="granite-page-container">
                    <h2 className='types-granite-title'>Види граніту</h2>
                    <div className="types-wrapper">
                        <div className="granite-type">
                            <h5 className='granite-name'>Габро Місцеве</h5>
                            <img src='images/types/bukinskii_gabbro.webp' />
                            <div className="type-desc">
                                Габро - природний камінь, що характеризується однорідною структурою та високою щільністю.
                                Порода відрізняється насиченим чорним кольором із невеликими вкрапленнями блакитного.
                                Габро має перший клас радіоактивності, дає можливість для використання у будь-яких будівельних роботах.
                            </div>
                        </div>
                        <div className="granite-type">
                            <h5 className='granite-name'>Габро Букинське</h5>
                            <img src='images/types/bukinskii_gabbro.webp' />
                            <div className="type-desc">
                                Габро - природний камінь, що характеризується однорідною структурою та високою щільністю.
                                Порода відрізняється насиченим чорним кольором із невеликими вкрапленнями блакитного.
                                Габро має перший клас радіоактивності, дає можливість для використання у будь-яких будівельних роботах.
                            </div>
                        </div>
                        <div className="granite-type">
                            <h5 className='granite-name'>Човнова</h5>
                            <img src='images/types/chovnova.webp' />
                            <div className="type-desc">
                                Граніт Човнова – це граніт зеленувато-коричневого кольору. Належить до першого класу радіоактивності.
                                Можна використовувати без обмежень. Належить до класу середньозернистих гранітів.
                                Завдяки мінералам у складі має унікальний, властивий лише цьому виду граніту малюнок.
                            </div>
                        </div>
                        <div className="granite-type">
                            <h5 className='granite-name'>Дідковичі</h5>
                            <img src='images/types/didkovichi.webp' />
                            <div className="type-desc">
                                Граніт Дідковицького родовища - найпоширеніший природний камінь,
                                що відноситься до класу поліхромних гранітів з ніжним рожево-коричневим кольором.
                                На текстурі породи зустрічаються кристалічні скупчення мікрокліну з вираженими плямами біотину.
                                Структура середньозерниста. Рівень природної радіації відповідає першому класу</div>
                        </div>
                        <div className="granite-type">
                            <h5 className='granite-name'>Добринський лабрадорит</h5>
                            <img src='images/types/dobrinskiy_labradorit.webp' />
                            <div className="type-desc">Добринський лабрадорит має гарну темно-синю кристалічну структуру природного каменю з переважним
                                включенням Плагіоклазу у породі. Має відмінні декоративні властивості.
                                Завдяки першому рівню природної радіації широко використовується у будівництві, архітектурі,
                                ландшафтному дизайні, декоративному оздобленні гранітом фасадів та інтер'єрів</div>
                        </div>
                        <div className="granite-type">
                            <h5 className='granite-name'>Федорівка</h5>
                            <img src='images/types/fedorivka.webp' />
                            <div className="type-desc">Малофедорівський граніт має це красиву повнокристалічну структуру, різноманітність відтінків,
                                а також відмінні експлуатаційні якості. Рівень природної радіації відповідає першому класу</div>
                        </div>
                        <div className="granite-type">
                            <h5 className='granite-name'>Жадківка</h5>
                            <img src='images/types/zhadkivka.webp' />
                            <div className="type-desc">
                                Граніт Жадковського родовища багато в чому схожий на італійський сорт каменю,
                                проте він має набагато кращі характеристики.
                                Жадківський граніт має красиву фактуру і колір при полірованій або термообробленій поверхності, добре полірується та піддаєтья термообробці. Рівень природної радіації відповідає першому класу</div>
                        </div>
                        <div className="granite-type">
                            <h5 className='granite-name'>Капустянський</h5>
                            <img src='images/types/kapustianskiy.webp' />
                            <div className="type-desc">
                                Граніт Капустинського родовища є одним із найбільш продаваних в Україні на сьогоднішній день.
                                Цю популярність він отримав завдяки своєму насиченому червоному кольору.
                                Використовується для виготовлення будівельної продукції: вхідні групи, стільниці, балясини, підвіконня,
                                пам'ятники та інше. Рівень природної радіації відповідає першому класу</div>
                        </div>
                        <div className="granite-type">
                            <h5 className='granite-name'>Корнинський</h5>
                            <img src='images/types/korninskiy.webp' />
                            <div className="type-desc">
                                Корнинський граніт відрізняється привабливим зовнішнім виглядом і піддатливістю до обробки.
                                Вироби з натурального каменю володіють масою переваг, серед яких високий рівень міцності і зносостійкості,
                                стійкість до впливу вологи та морозу,
                                стійкість до стирання та інше.</div>
                        </div>
                        <div className="granite-type">
                            <h5 className='granite-name'>Лезниківський</h5>
                            <img src='images/types/liznik.webp' />
                            <div className="type-desc">
                                Граніт Лезниківського родовища вважається видатним і унікальним серед усіх відомих порід граніту.
                                Його унікальність - це неповторний червоний колір із відтінками від світло-бордового до темно-червоного. Рівень природної радіації відповідає першому класу</div>
                        </div>
                        <div className="granite-type">
                            <h5 className='granite-name'>Маславський</h5>
                            <img src='images/types/maslavka.webp' />
                            <div className="type-desc">
                                Граніт Маславського родовища темно-зеленого кольору з безліччю дрібних темних та світлих вкраплень.
                                Цей вид відноситься до середньозернистих поліморфних гранітів.
                                Особливо цей граніт популярний у полірованому вигляді, оскільки полірування дозволяє повною мірою проявитися неповторному малюнку та кольоровій гамі цього каменю. Рівень природної радіації відповідає першому класу</div>
                        </div>
                        <div className="granite-type">
                            <h5 className='granite-name'>Новоданилівський</h5>
                            <img src='images/types/novodanilivskiy.webp' />
                            <div className="type-desc">
                                Новоданилівський граніт – червоний камінь, який відноситься до цінних порід.
                                Характерною особливістю цієї породи є глибокий червоний колір із темними вкрапленнями.
                                Подібне забарвлення робить граніт популярним у багатьох сферах будівництва та промисловості.</div>
                        </div>
                        <div className="granite-type">
                            <h5 className='granite-name'>Очеретянський лабрадорит</h5>
                            <img src='images/types/ocheretyanskiy_labradorit.webp' />
                            <div className="type-desc">
                                Лабрадорит Очеретянський – порода магматичного походження, яка відноситься до групи порід анортозитів.
                                Важливо розуміти, що лабрадоритом називають породу, з якої видобуваються напівдорогоцінні виробні камені
                                – лабрадори.</div>
                        </div>
                        <div className="granite-type">
                            <h5 className='granite-name'>Осмалинський</h5>
                            <img src='images/types/osmalinskiy.webp' />
                            <div className="type-desc">
                                Камінь вважається одним із найміцніших та морозостійких серед мінералів, що видобуваються в Україні.
                                Це з великим змістом кварцу. Порода відноситься до першого класу природного випромінювання.
                                Граніт із Осмалинського родовища застосовується у будь-яких видах будівельних робіт. Рівень природної радіації відповідає першому класу</div>
                        </div>
                        <div className="granite-type">
                            <h5 className='granite-name'>Покостівський</h5>
                            <img src='images/types/pokostivka.webp' />
                            <div className="type-desc">
                                Покостівський граніт – один з найбільш доступних за вартістю каменів, який відзначається 
                                сіруватим кольором з чорними вкрапленнями. Завдяки своїй щільності та вологостійкості, вироби 
                                з цього граніту зберігають свою цілісність протягом тривалого часу. Покостівський граніт відноситься 
                                до першого класу радіоактивності, що дозволяє використовувати його для всіх видів зовнішніх 
                                та внутрішніх робіт (один із прикладів – гранітні плити). 
                                </div>
                        </div>
                        <div className="granite-type">
                            <h5 className='granite-name'>Рахна-Польова</h5>
                            <img src='images/types/rahna_polyova.webp' />
                            <div className="type-desc">
                                Дана порода має насичений колір і однорідну структуру без іризації, не має великих вкраплень інших тонів.
                                Завдяки цьому продукція з Рахни-Полівського граніту чудово вписується у будь-який стиль оформлення:
                                від класики до сучасних дизайнерських рішень. Рівень природної радіації відповідає першому класу</div>
                        </div>
                        <div className="granite-type">
                            <h5 className='granite-name'>Токівський</h5>
                            <img src='images/types/tokivskiy.webp' />
                            <div className="type-desc">
                                Токівський гранітний кар'єр багатий на камінь, що володіє дрібнозернистою структурою.
                                Викопне переважно використовується для зовнішньої обробки, що пов'язано з присвоєним йому другим
                                ступенем радіоактивності. Граніт популярний не лише в Україні, а й за її межами.</div>
                        </div>
                        <div className="granite-type">
                            <h5 className='granite-name'>Василівський</h5>
                            <img src='images/types/vasilivka.webp' />
                            <div className="type-desc">
                                Граніт Василівського родовища - високодекоративний граніт із середньою зернистістю,
                                свою популярність цей камінь черпає завдяки оригінальному вигляду, приємній палітрі,
                                а також граніт практично не поглинає вологу і має вражаючий міцний кордон при стисканні,
                                не піддається подряпинам, природним та хімічним пошкодженням. </div>
                        </div>
                        <div className="granite-type">
                            <h5 className='granite-name'>Осниківський лабрадорит</h5>
                            <img src='images/types/osinkivskiy_labradorit.webp' />
                            <div className="type-desc">
                                Осниківський лабрадорит – це лабрадорит темно-сірого кольору.
                                Він є дуже привабливою, унікальною структурою.
                                Його малюнок складається з прожилок зеленого кольору, які надають йому аристократичного вигляду.
                                Саме завдяки малюнку та хорошим фізико-механічним характеристикам, він потіснив деякі види граніту,
                                і стрімко набирає популярності. Рівень природної радіації відповідає першому класу</div>
                        </div>
                    </div>
                </div>

            </CommonLayout>
        </>
    )
}

export default TypeOfStone;