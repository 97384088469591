import { ViewColumn } from '@mui/icons-material';
import IconButton from '../Common/IconButton/button';
import './style.scss';

const Footer = () => {
    return (
        <>
            <footer>
                <div className="footer-wrapper">
                    <div className="footer-contact">
                        <div className="contact-item">
                            <IconButton iconUrl={'icons/phone-icon.svg'} width={25} height={25} alt='phone' />
                            <div className="column">
                                <a href='tel:+38(097)686-68-65'>+38(097)686-68-65</a>
                                <a href='tel:+38(050)649-66-94'>+38(050)649-66-94</a>
                                <a href='tel:+38(097)063-38-39'>+38(097)063-38-39</a>
                            </div>
                        </div>
                        <div className="contact-item">
                            <IconButton iconUrl={'icons/email-icon.svg'} width={25} height={25} alt='email' />
                            granfactory@gmail.com
                        </div>
                    </div>
                    <div className="footer-contact">
                        <div className="contact-item"><IconButton iconUrl={'icons/time-icon.svg'} width={25} height={25} alt='time' />
                            Пн. - Пт. 8:00 - 17:00</div>
                        <div className="contact-item"><IconButton iconUrl={'icons/place-icon.svg'} width={25} height={25} alt='place' />
                            <a href='https://maps.app.goo.gl/ADXszFKRF2kcdxyn6' target='blank'>Житомирська обл., м. Коростишів, вул. Шухевича (Грибоєдова), 37а
                            </a>
                        </div>
                    </div>
                </div>
            </footer >
        </>
    )
}

export default Footer;