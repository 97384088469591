import './style.scss';

const SecondaryButton = ({width, height, text, callback}) => {
    return (
        <>
            <button className="component-button secondary-button" style={{width: width, height: height}} onClick={callback}>
                {text}
            </button>
        </>
    )
}

export default SecondaryButton;