import React, { useEffect, useState } from 'react';
import './style.scss';
import CommonLayout from '../../components/Common/Layout/layout';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { CatalogData } from '../../data/catalog';
import Icon from '../../components/Common/Icon/icon';
import PrimaryButton from '../../components/Common/Button/primary-button';
import { useQuery } from '../../logic/hooks/useQuery';
import CartRow from '../../components/Common/CartComponent/components/cart-item';
import { CartData } from '../../data/cart-content';

const SalesPage = () => {


    return (
        <>
            <CommonLayout backgroundColor={'black'}>
                <div className="sales-page-container">
                    <h2>Акції</h2>
                </div>
            </CommonLayout>
        </>
    )
}

export default SalesPage;