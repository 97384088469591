import React, { useEffect } from 'react';
import './style.scss';
import CommonLayout from '../../components/Common/Layout/layout';
import { Link } from 'react-router-dom';

const ThanksPage = ({ cartData, setCartData }) => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <>
            <CommonLayout background={'white'}>
                <div className="thanks-page-container">                    
                    <p className="thanks-text-title">Дякуємо за замовлення</p>
                    <p className="thanks-text">Найближчим часом або в робочі години з вами зв'яжиться менеджер з продажу</p>
                </div>

            </CommonLayout>
        </>
    )
}

export default ThanksPage;