import './style.scss';
import { CartData } from '../../../data/cart-content'
import ModalWindow from '../ModalWindow/modal';
import PrimaryButton from '../Button/primary-button';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import IconButton from '../IconButton/button';
import { MailLockOutlined, MailOutlineOutlined } from '@mui/icons-material';

const ContactButton = () => {
    const [modalOpen, setModalOpen] = useState(false);

    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    return (
        <>
            <div className="call-now-button">
                <a href='tel:+380970633839'><IconButton width={'36px'} height={'36px'} iconUrl={'/icons/phone-icon.svg'} alt='call-now' /></a>
            </div>

            <div className="contact-button" onClick={()=>setModalOpen(true)}>
                <MailOutlineOutlined sx={{color:'white'}} fontSize='large'/>
            </div>
            <ModalWindow modalOpen={modalOpen} closeModal={closeModal} />
        </>

    )
}

export default ContactButton;