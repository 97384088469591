import React, { useState } from 'react';
import './styles/style.css';
import MainPage from './pages/MainPage/main';
import Header from './components/HeaderComponent/header';
import {
  Route,
  Routes,
  BrowserRouter
} from "react-router-dom";
import Footer from './components/FooterComponent/footer';
import CatalogPage from './pages/CatalogPage/catalog';
import DetailProduct from './pages/DetailProductCart/detail-product';
import OrderPage from './pages/OrderPage/order-page';
import SalesPage from './pages/SalesPage/order-page';
import ContactButton from './components/Common/CallNow/callnow';
import TypeOfStone from './pages/TypeOfStone/main';
import ContactPage from './pages/ContactPage/main';
import ThanksPage from './pages/ThanksPage/main';
import ProductionPage from './pages/ProductionPage/main';
import CooperationPage from './pages/CooperationPage/main';
import DeliveryPage from './pages/DeliveryPage/main';
import CategoriesPage from './pages/CategoriesPage/main';
import 'lazysizes';
// import a plugin
import 'lazysizes/plugins/parent-fit/ls.parent-fit';


function App() {
  const [cartData, setCartData] = useState([])

  return (
    <BrowserRouter>
      <div className="body">
        <Header cartData={cartData} setCartData={setCartData}/>
        <Routes>
          <Route path="/" element={<MainPage></MainPage>} />
          <Route path="/catalog" element={<CatalogPage cartData={cartData} setCartData={setCartData}></CatalogPage>} />
          <Route path="/catalog/:id" element={<DetailProduct cartData={cartData} setCartData={setCartData}></DetailProduct>} />
          <Route path="/order-confirm" element={<OrderPage cartData={cartData} setCartData={setCartData}></OrderPage>} />
          <Route path="/sales" element={<SalesPage cartData={cartData} setCartData={setCartData}></SalesPage>} />
          <Route path="/type-of-stone" element={<TypeOfStone cartData={cartData} setCartData={setCartData}></TypeOfStone>} />
          <Route path="/contacts" element={<ContactPage cartData={cartData} setCartData={setCartData}></ContactPage>} />
          <Route path="/thanks-page" element={<ThanksPage cartData={cartData} setCartData={setCartData}></ThanksPage>} />
          <Route path="/production-page" element={<ProductionPage cartData={cartData} setCartData={setCartData}></ProductionPage>} />
          <Route path="/cooperation-page" element={<CooperationPage cartData={cartData} setCartData={setCartData}></CooperationPage>} />
          <Route path="/delivery-page" element={<DeliveryPage cartData={cartData} setCartData={setCartData}></DeliveryPage>} />
          <Route path="/select-category" element={<CategoriesPage cartData={cartData} setCartData={setCartData}></CategoriesPage>} />
        </Routes>
        <Footer />
        <ContactButton />
      </div>
    </BrowserRouter>
  );
}

export default App;
