import React, { useEffect, useState } from 'react';
import './style.scss';
import CommonLayout from '../../components/Common/Layout/layout';
import { Grid } from '@mui/material';
import { useQuery } from '../../logic/hooks/useQuery';
import { CatalogData } from '../../data/catalog';
import { GalleryProducts } from '../../data/gallery';
import { BuildingMaterials, BuildingMaterialsForFilterBlock, Fencing, FencingForFilterBlock, GraniteAccessories, GraniteAccessoriesForFilterBlock, HomeDecor, HomeDecorForFilterBlock, MarbleProducts, MarbleProductsForFilterBlock, Monuments, MonumentsForFilterBlock, Spotproducts, SpotproductsForFilterBlock } from '../../logic/const/constants';
import { useNavigate } from 'react-router-dom';

const CategoriesPage = ({ cartData, setCartData }) => {

    const query = useQuery();
    const category = query.get('category')
    const navigate = useNavigate();
    const [subcategories, setSubcategories] = useState([]);

    const onRedirectToCategory = (subCategory) => {
        navigate(`/catalog?category=${category}&subCategory=${subCategory}`, { replace: true });
    }

    useEffect(() => {
        window.scrollTo(0, 0)

        if (category) {
            switch (category) {
                case (Monuments): setSubcategories(MonumentsForFilterBlock); break;
                case (Spotproducts): setSubcategories(SpotproductsForFilterBlock); break;
                case (GraniteAccessories): setSubcategories(GraniteAccessoriesForFilterBlock); break;
                case (HomeDecor): setSubcategories(HomeDecorForFilterBlock); break;
                case (Fencing): setSubcategories(FencingForFilterBlock); break;
                case (BuildingMaterials): setSubcategories(BuildingMaterialsForFilterBlock); break;
                case (MarbleProducts): setSubcategories(MarbleProductsForFilterBlock); break;
            }
        }
    })

    return (
        <>
            <CommonLayout background={'white'}>
                <div className="categories-page-container">
                    <div className='navigation' onClick={() => navigate(-1)}>← Повернутись на головну</div>
                    <h4>Вибір категорії</h4>
                    <Grid container spacing={2} columns={{ xs: 2, sm: 3, md: 4 }}>
                        {subcategories.map((x) => <Grid item xs={1}>
                            <div className="category-block"  style={{backgroundImage:`url('${x[2]}')`}} onClick={() => { onRedirectToCategory(x[0]) }}>
                                <div className='title'>{x[1]}</div>
                            </div>
                        </Grid>
                        )}
                    </Grid>
                </div>

            </CommonLayout>
        </>
    )
}

export default CategoriesPage;