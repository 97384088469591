import { useState } from 'react';
import React from 'react';
import Catalog from '../Common/CatalogComponent/catalog';
import IconButton from '../Common/IconButton/button';
import './style.scss';
import {
    Link, redirect, useNavigate, useSearchParams
} from "react-router-dom";
import Cart from '../Common/CartComponent/cart';
import { useEffect } from 'react';

const Header = ({ cartData, setCartData }) => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isCartOpen, setIsCartOpen] = useState(false);
    const [cartCount, setCartCount] = useState(cartData.length);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const toggleCart = () => {
        setIsCartOpen(!isCartOpen);
    };

    const onChangeSearch = (event) => {
        const value = event?.target.value;
        if (value == '') {
            navigate(`/catalog`)
        } else {
            navigate(`/catalog?search=${value}`)
        }
    }

    useEffect(() => {
        setCartCount(cartData.length)
    }, [cartData])

    return (
        <>
            <header>
                <div className="left">
                    <Link to="/">
                        <div className="logo">
                            GRAN FACTORY
                            <div className="sub-text">Вироби з граніту</div>
                        </div>
                    </Link>
                </div>
                <div className="center">
                    <div className="menu">
                        <div onClick={toggleMenu}>
                            <IconButton width={'36px'} height={'36px'} iconUrl={'/icons/menu-button.svg'} alt='menu' />
                        </div>
                        <Catalog isOpen={isMenuOpen} onClose={toggleMenu} />
                    </div>
                    <div className="search">
                        <input type='text' name='search-text' onChange={onChangeSearch}></input>
                        <div className="icon">
                            <img src='/icons/search-icon.svg' alt='search' />
                        </div>
                    </div>
                    <div className="cart-pc">
                        <div class="cart-icon-wrapper" onClick={toggleCart}>
                            <div className="cart-count">{cartCount}</div>
                            <IconButton width={'36px'} height={'36px'} iconUrl={'/icons/cart-icon.svg'} alt='cart' />
                        </div>
                        <Cart isOpen={isCartOpen} onClose={toggleCart} cartData={cartData} setCartData={setCartData} />
                    </div>
                    <div className="cart-mobile">
                        <Link class="cart-icon-wrapper" to={'/order-confirm'}>
                            <div className="cart-count">{cartCount}</div>
                            <IconButton width={'36px'} height={'36px'} iconUrl={'/icons/cart-icon.svg'} alt='cart' />
                        </Link>
                    </div>
                </div>
                <div className="right">
                    <div className="contacts">
                        <a href="viber://chat?number=+380970633839"><IconButton width={'24px'} height={'24px'} iconUrl={'/icons/viber-icon.svg'} alt='viber' /></a>
                        <a href="https://t.me/+380970633839"><IconButton width={'24px'} height={'24px'} iconUrl={'/icons/telegram-icon.svg'} alt='telegram'  /></a>
                        <a target="_blank" href="https://www.instagram.com/gran.factory/"><IconButton width={'24px'} height={'24px'} iconUrl={'/icons/inst-icon.svg'} alt='instagram' /></a>
                        <div className="phone-number">
                            +38 (097) 0633 839
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header;