import React, { useState } from 'react';
import './style.scss';
import { Link } from 'react-router-dom';
import { Cobblestones, FacadeTiles, PavingTiles, Slabs } from '../../../../logic/const/constants';
import { initializePrice } from '../../../../utils';

const ProductCart = ({ id, urlImg, price, subCategory, code, stone, addProductToCart }) => {
    const [imageCount, setImageCount] = useState(0)

    const AddToCart = (e) => {
        e.preventDefault();
        addProductToCart({ id, urlImg, price, code, stone, count: 1 })
    }

    return (
        <>
            <Link to={id}>
                <div className="product-cart">
                    <div className="product-image">
                        <img src={typeof (urlImg) == typeof ([]) ? urlImg[imageCount] : urlImg} className='lazyload' alt={code} />
                    </div>
                    <div className="product-desc-wrapper">
                        <div className="product-price">
                            {typeof (price) == typeof ([]) ? `${price[0]}` : `${price}`} <br />
                            {initializePrice(subCategory)}
                        </div>
                        <div className="product-desc">
                            <div className="row">
                                <div className="row-name">Код</div>
                                <div className="row-value">{code}</div>
                            </div>
                            <div className="row">
                                <div className="row-name">Порода</div>
                                <div className="row-value">{stone}</div>
                            </div>
                        </div>
                    </div>

                    <div className="buttons">
                        {/* <div className="in-cart">
                        <IconButton iconUrl={'icons/black-cart.svg'} width={'30px'} height={'30px'}></IconButton>
                    </div> */}
                        <button className="primary-button order-by" onClick={AddToCart}>Додати в кошик</button>
                    </div>
                </div>
            </Link>

        </>
    )
}

export default ProductCart;