import React, { useEffect, useState } from 'react';
import './main.scss';
import CommonLayout from '../../components/Common/Layout/layout';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { CatalogData } from '../../data/catalog';
import Icon from '../../components/Common/Icon/icon';
import PrimaryButton from '../../components/Common/Button/primary-button';
import { useQuery } from '../../logic/hooks/useQuery';
import { CartData } from '../../data/cart-content';
import { Fade, Slide } from 'react-slideshow-image';
import { Balusters, Cobblestones, FacadeTiles, Monuments, PavingTiles, Slabs } from '../../logic/const/constants';
import ModalWindow from '../../components/Common/ModalWindow/modal';
import SecondaryButton from '../../components/Common/Button/secondary-button';
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { initializePrice } from '../../utils';




const DetailProduct = ({ cartData, setCartData }) => {
    const { id } = useParams();
    const query = useQuery();
    const product = CatalogData.find(x => x.id == id);
    const navigate = useNavigate();
    const [imageCount, setImageCount] = useState(0);
    const [selectedStone, setSelectedStone] = useState(0)
    const [modalOpen, setModalOpen] = useState(false);

    const [rows, setRows] = useState([])

    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };


    const AddProductToCart = () => {
        let index = cartData.findIndex(x => x.id == product.id && x.price == product.price[selectedStone]);
        if (index != -1) {
            var newCart = [...cartData];
            newCart[index].count += 1
            setCartData(newCart)
        } else {
            setCartData(cartData => [...cartData, {
                id: product.id,
                category: product.category,
                subCategory: product.subCategory,
                urlImg: product.urlImg,
                code: product.code,
                price: product.price[selectedStone],
                stone: product.stone,
                typeOfStone: product.types ? product.types[selectedStone].name : null,
                count: 1
            }]);
        }
    }

    const nextImage = () => {
        if (product.urlImg.length - 1 > imageCount) {
            setImageCount(imageCount + 1)
        } else {
            setImageCount(0)
        }
    }

    const previousImage = () => {
        if (imageCount != 0) {
            setImageCount(imageCount - 1)
        }
    }

    const changeTypeOfStone = (index) => {
        setSelectedStone(index)
    }


    function createData(arka, tumba, kvitnuk, price) {
        return { arka, tumba, kvitnuk, price };
    }




    // const rows = [
    //     product.types[0].map(x => {
    //         return createData(x.size, x.artistic, x.price, x.weight)
    //     })
    // ];


    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    }, [])

    useEffect(() => {
        let rows = product.types && product.types[selectedStone].sizes.map(x => {
            return createData(x.arka, x.tumba, x.kvitnuk, x.price)
        })
        debugger
        setRows(rows)
    }, [selectedStone])


    return (
        <>
            <CommonLayout background={'url(images/background3.jpg)'}>
                <div className="detail-product-container">
                    <div className='navigation' onClick={() => navigate(-1)}>← Повернутись до каталогу</div>
                    <div className="product-block">
                        <div className="product-image">
                            <div className="product-image-wrapper">
                                <div className="arrow-wrapper left" onClick={previousImage} style={{ display: typeof (product.urlImg) == typeof ([]) ? "" : "none" }}>
                                    <Icon iconUrl={"/icons/arrow.svg"} width={"40px"} height={"40px"}></Icon>
                                </div>
                                {typeof (product.urlImg) == typeof ([])
                                    ?
                                    <div>
                                        {product.urlImg.map(x => <img
                                            src={x}
                                            className={`product-image ${imageCount == product.urlImg.indexOf(x) ? 'active' : ''}`}
                                        ></img>)}
                                    </div>
                                    :
                                    <div>
                                        <img
                                            src={product.urlImg}
                                            className='single-product-image'
                                        ></img>
                                    </div>

                                }
                                <div className="arrow-wrapper" onClick={nextImage} style={{ display: typeof (product.urlImg) == typeof ([]) ? "" : "none" }}>
                                    <Icon iconUrl={"/icons/arrow.svg"} width={"40px"} height={"40px"}></Icon>
                                </div>
                            </div>
                        </div>
                        <div className="product-detail">
                            <p>Артикул: {product.code}</p>
                            <div className="price">
                                Ціна: {typeof (product.price) == typeof ([]) ? product.price[selectedStone] : product.price}
                                {initializePrice(product.subCategory)}
                            </div>
                            <div className="desc">
                                <b>Порода:</b> {product.stone}<br />
                                <b>Розміри:</b> {product.sizes}<br />
                                {product.note && <div><b>Примітка:</b> {product.note}</div>}
                            </div>


                            {product.types &&
                                <>
                                    <div className='button-group'>
                                        {
                                            product.types.map((x, index) => <button className={`select-button ${selectedStone == index ? 'active' : ''}`} onClick={() => changeTypeOfStone(index)}>{x.name}</button>)
                                        }
                                    </div>
                                    <div className="info-table">
                                        <TableContainer component={Paper}>
                                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="right">Арка</TableCell>
                                                        <TableCell align="right">Тумба</TableCell>
                                                        <TableCell align="right">Квітник</TableCell>
                                                        <TableCell align="right">Ціна, грн</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {rows.map((row) => (
                                                        <TableRow
                                                            key={row.name}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell align="right">{row.arka}</TableCell>
                                                            <TableCell align="right">{row.tumba}</TableCell>
                                                            <TableCell align="right">{row.kvitnuk}</TableCell>
                                                            <TableCell align="right"><b>{row.price}</b></TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </>
                            }
                            {product.additional && <div className='additional-items'><b>У комплект входить:</b> {product.additional}</div>}

                            <div className='button-group'>
                                <PrimaryButton width={"50%"} height={"50px"} text={'Додати в кошик'} callback={AddProductToCart}></PrimaryButton>
                                <SecondaryButton width={"50%"} height={"50px"} text={'Залишити заявку'} callback={() => setModalOpen(true)} ></SecondaryButton>
                            </div>
                            <div className="line"></div>
                            <div className="conditions">
                                <div className="temp">
                                    <Icon iconUrl={"/icons/color-swatch-svgrepo-com.svg"} width={"30px"} height={"30px"}></Icon>
                                    <p>
                                        Колір може відрізнятися від фото чи від 3D візуалізації, через неповторну текстуру натурального камню
                                    </p>
                                </div>
                                <div className="temp">
                                    <Icon iconUrl={"/icons/sizes.svg"} width={"30px"} height={"30px"}></Icon>
                                    <p>
                                        Всі розміри виробу можна редагувати і змінювати вигляд в залежності від ваших побажань, дизайнерських рішень.
                                    </p>
                                </div>
                                <div className="temp" style={{ display: product.category == Monuments ? "" : "none" }} >
                                    <Icon iconUrl={"/icons/setup.svg"} width={"30px"} height={"30px"}></Icon>
                                    <p>
                                        Встановлення пам'ятника відбувається нашою стороною або самостійно. Монтаж пам'ятника в ціну не входить
                                    </p>
                                </div>
                                <div className="temp">
                                    <Icon iconUrl={"/icons/delivery.svg"} width={"30px"} height={"30px"}></Icon>
                                    <p>
                                        Доставка в межах України здійснюється собі вартістю транспорту або поштовими службами (Нова Пошта, УкрПошта, ІнТайм та інші). Наші менеджери підберуть найбільш оптимальний спосіб доставки індивідуально.
                                    </p>
                                </div>
                                <div className="temp">
                                    <Icon iconUrl={"/icons/money.svg"} width={"30px"} height={"30px"}></Icon>
                                    <p>
                                        Ми приймаємо різні види платежів: готівка, наложений платіж, переказ на банківський рахунок, оплата картками Visa/MasterCard                                </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </CommonLayout>
            <ModalWindow modalOpen={modalOpen} closeModal={closeModal} />
        </>
    )
}

export default DetailProduct;