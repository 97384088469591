import { DeleteForeverOutlined, DeleteOutline } from '@mui/icons-material';
import IconButton from '../../IconButton/button';
import './style.scss';

const CartRow = ({ id, urlImg, code, typeOfStone, count, price, onDeleteItem }) => {
    return (
        <>
            <div className="cart-row">
                <div className="product-image">
                    <img src={typeof (urlImg) == typeof ([]) ? urlImg[0] : urlImg} />
                </div>
                <div class='product-code'>{code} {typeOfStone != null ? `(${typeOfStone})` : ``}</div>
                <div>Шт: {count}</div>
                <div className="price-column">
                    <div className="single-row-price">Ціна за одиницю: <span className='bold-text'>{price} ₴</span></div>
                    <div className="total-row-price">Всього: <span className='bold-text'>{(typeof (price) == typeof (0)) ? price * count : 0} ₴</span></div>
                </div>
                <div onClick={e => onDeleteItem(e, id)} style={{ cursor: 'pointer' }} >
                    <DeleteOutline fontSize='large' />
                </div>
            </div>
        </>

    )
}

export default CartRow;