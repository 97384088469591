import './style.scss';

const IconButton = ({iconUrl, width, height, alt}) => {
    return (
        <>
            <button className="icon-button" >
                <img src={iconUrl} width={width} height={height} alt={alt} />
            </button>
        </>
    )
}

export default IconButton;