import React from 'react';
import './style.scss';
import {
    Fencing, GraniteAccessories, HomeDecor, Monuments,
    Spotproducts, MarbleProducts, BuildingMaterials, Single,
    Double, Family, ForAnimals, Childrens, Military, Exclusive, Urns, Addition, MonumentsForFilterBlock, SpotproductsForFilterBlock, GraniteAccessoriesForFilterBlock, FencingForFilterBlock, HomeDecorForFilterBlock, BuildingMaterialsForFilterBlock, MarbleProductsForFilterBlock
} from '../../../../logic/const/constants';
import { Link } from 'react-router-dom';
import { useQuery } from '../../../../logic/hooks/useQuery';

const FilterBlock = ({ visible, setVisible, onChangeMinPrice, onChangeMaxPrice }) => {
    const query = useQuery();
    const category = query.get('category')
    let subCategory = [];
    
    switch (category) {
        case (Monuments):
            subCategory = MonumentsForFilterBlock;
            break;
        case (Spotproducts):
            subCategory = SpotproductsForFilterBlock;
            break;
        case (GraniteAccessories):
            subCategory = GraniteAccessoriesForFilterBlock;
            break;
        case (Fencing):
            subCategory = FencingForFilterBlock;
            break;
        case (HomeDecor):
            subCategory = HomeDecorForFilterBlock;
            break;
        case (MarbleProducts):
            subCategory = MarbleProductsForFilterBlock;
            break;
        case (BuildingMaterials):
            subCategory = BuildingMaterialsForFilterBlock;
            break;
        default: subCategory = [];
    }

    const renderItems = () => {
        return subCategory.map(x => {
            return <li><Link to={`?category=${category}&subCategory=${x[0]}`}>{x[1]}</Link></li>
        });
    }



    return (
        <>
            <div className={`filter-block ${visible == true ? 'show' : ''}`}>
                <div className="close-button" onClick={()=>setVisible(false)}>X</div>
                <div className="filter">
                    <div className="title">
                        КАТЕГОРІЯ
                    </div>
                    <ul>
                        <li><Link to={`?category=${Monuments}`}>Пам'ятникі</Link></li>
                        <li><Link to={`?category=${Spotproducts}`}>Точечні вироби</Link></li>
                        <li><Link to={`?category=${GraniteAccessories}`}>Гранітні аксесуари</Link></li>
                        <li><Link to={`?category=${Fencing}`}>Забори, огорожі</Link></li>
                        <li><Link to={`?category=${HomeDecor}`}>Декор для дому</Link></li>
                        <li><Link to={`?category=${MarbleProducts}`}>Мармурові вироби</Link></li>
                        <li><Link to={`?category=${BuildingMaterials}`}>Будівельні матеріали</Link></li>
                    </ul>
                </div>
                <div className="filter">
                    <div className="title">
                        ТИП ТОВАРА
                    </div>
                    <ul>
                        {renderItems()}
                    </ul>
                </div>
                <div className="filter">
                    <div className="title">
                        ЦІНА
                    </div>
                    <div className="price-input-group">
                        <input type="number" placeholder='Від' onChange={e => onChangeMinPrice(e.target.value)}></input>
                        <input type="number" placeholder='До' onChange={e => onChangeMaxPrice(e.target.value)}></input>
                    </div>
                </div>
                {/* <div className="filter">
                    <div className="title">
                        ПОРОДА
                    </div>
                </div> */}
            </div>
        </>
    )
}

export default FilterBlock;