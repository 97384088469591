import { Link } from 'react-router-dom';
import './style.scss';
import { Addition, Ashtray, Balls, Balusters, Boulders, BuildingMaterials, CandyBoxes, Childrens, Cobblestones, Countertops, CrushedStone, Curbs, CuttingBoards, Double, Exclusive, FacadeTiles, Family, Fencing, ForAnimals, Fountains, ForMonuments, GraniteAccessories, HomeDecor, Info, Lampad, MarbleProducts, Military, Monuments, NapkinHolder, PavingTiles, Railings, Single, SinksBathtubs, Slabs, SoccerBalls, Spheres, Spotproducts, Stairs, Urns, Vases, WindowSills, Gabions, Fenc, Crosses } from '../../../logic/const/constants';
import { useState } from 'react';
import { HomeOutlined } from '@mui/icons-material';

const Catalog = ({ isOpen, onClose }) => {

    const [selectedCategory, setSelectedCategory] = useState(Monuments)

    const setCategory = (category) => {
        setSelectedCategory(category)
    }

    return (
        <>
            <div className={`catalog-background ${!isOpen ? 'close' : ''}`} onClick={onClose}>
            </div>
            <div className={`catalog-pc ${!isOpen ? 'close' : ''}`}>
                <div className="list" gridArea={'A'}>
                    <div className="title"><Link to={`catalog?category=${Monuments}`} onClick={onClose}>Пам'ятники</Link></div>
                    <ul>
                        <li><Link to={`catalog?category=${Monuments}&subCategory=${Single}`} onClick={onClose}>Одинарні</Link></li>
                        <li><Link to={`catalog?category=${Monuments}&subCategory=${Double}`} onClick={onClose}>Подвійні</Link></li>
                        <li><Link to={`catalog?category=${Monuments}&subCategory=${Military}`} onClick={onClose}>Військові</Link></li>
                        <li><Link to={`catalog?category=${Monuments}&subCategory=${Family}`} onClick={onClose}>Сімейні</Link></li>
                        <li><Link to={`catalog?category=${Monuments}&subCategory=${Childrens}`} onClick={onClose}>Дитячі</Link></li>
                        <li><Link to={`catalog?category=${Monuments}&subCategory=${ForAnimals}`} onClick={onClose}>Для тварин</Link></li>
                        <li><Link to={`catalog?category=${Monuments}&subCategory=${Exclusive}`} onClick={onClose}>Ексклюзивні</Link></li>
                        <li><Link to={`catalog?category=${Monuments}&subCategory=${Addition}`} onClick={onClose}>Доповнення до пам’ятників</Link></li>
                        <li><Link to={`catalog?category=${Fencing}&subCategory=${ForMonuments}`} onClick={onClose}>Огорожі для пам'ятників</Link></li>
                    </ul>
                </div>
                <div className="list" gridArea={'B'}>
                    <div className="title"><Link to={`catalog?category=${Spotproducts}`} onClick={onClose}>Точені вироби</Link></div>
                    <ul>
                        <li><Link to={`catalog?category=${Spotproducts}&subCategory=${Vases}`} onClick={onClose}>Вази</Link></li>
                        <li><Link to={`catalog?category=${Spotproducts}&subCategory=${Balls}`} onClick={onClose}>Кулі</Link></li>
                        <li><Link to={`catalog?category=${Spotproducts}&subCategory=${Spheres}`} onClick={onClose}>Сфери</Link></li>
                        <li><Link to={`catalog?category=${Spotproducts}&subCategory=${Railings}`} onClick={onClose}>Перила</Link></li>
                        <li><Link to={`catalog?category=${Spotproducts}&subCategory=${Balusters}`} onClick={onClose}>Балясини</Link></li>
                        <li><Link to={`catalog?category=${Spotproducts}&subCategory=${Lampad}`} onClick={onClose}>Лампадки</Link></li>
                    </ul>
                </div>
                <div className="list" gridArea={'C'}>
                    <div className="title"><Link to={`catalog?category=${GraniteAccessories}`} onClick={onClose}>Гранітні Аксесуари</Link></div>

                    <ul>
                        <li><Link to={`catalog?category=${GraniteAccessories}&subCategory=${Urns}`} onClick={onClose}>Урни для праху</Link></li>
                        <li><Link to={`catalog?category=${GraniteAccessories}&subCategory=${Ashtray}`} onClick={onClose}>Попільнички</Link></li>
                        <li><Link to={`catalog?category=${GraniteAccessories}&subCategory=${NapkinHolder}`} onClick={onClose}>Сарветниці</Link></li>
                        <li><Link to={`catalog?category=${GraniteAccessories}&subCategory=${CuttingBoards}`} onClick={onClose}>Дошки для нарізання</Link></li>
                        <li><Link to={`catalog?category=${GraniteAccessories}&subCategory=${CandyBoxes}`} onClick={onClose}>Цукерниці</Link></li>
                        <li><Link to={`catalog?category=${GraniteAccessories}&subCategory=${SoccerBalls}`} onClick={onClose}>Футбольні м'ячі</Link></li>
                        <li><Link to={`catalog?category=${GraniteAccessories}&subCategory=${Crosses}`} onClick={onClose}>Хрестики</Link></li>
                    </ul>
                </div>
                <div className="list" gridArea={'D'}>
                    <div className="title"><Link to={`catalog?category=${HomeDecor}`} onClick={onClose}>Декор для дому</Link></div>
                    <ul>
                        <li><Link to={`catalog?category=${HomeDecor}&subCategory=${Countertops}`} onClick={onClose}>Стільниці</Link></li>
                        <li><Link to={`catalog?category=${HomeDecor}&subCategory=${WindowSills}`} onClick={onClose}>Підвіконня</Link></li>
                        <li><Link to={`catalog?category=${HomeDecor}&subCategory=${Stairs}`} onClick={onClose}>Сходи</Link></li>
                        <li><Link to={`catalog?category=${HomeDecor}&subCategory=${Fountains}`} onClick={onClose}>Фонтани</Link></li>
                        <li><Link to={`catalog?category=${HomeDecor}&subCategory=${Boulders}`} onClick={onClose}>Валуни</Link></li>
                        <li><Link to={`catalog?category=${HomeDecor}&subCategory=${SinksBathtubs}`} onClick={onClose}>Раковини та ванни</Link></li>

                    </ul>
                </div>

                <div className="list" gridArea={'A'}>
                    <div className="title"><Link to={`catalog?category=${Fencing}`} onClick={onClose}>Паркани, огорожі</Link></div>

                    <ul>
                        <li><Link to={`catalog?category=${Fencing}&subCategory=${Fenc}`} onClick={onClose}>Паркани</Link></li>
                        <li><Link to={`catalog?category=${Fencing}&subCategory=${Gabions}`} onClick={onClose}>Габіони</Link></li>
                    </ul>
                </div>
                <div className="list" gridArea={'B'}>
                    <div className="title"><Link to={`catalog?category=${MarbleProducts}`} onClick={onClose}>Мармурові вироби</Link></div>

                    <ul>
                        <li>Скульптури</li>
                    </ul>
                </div>
                <div className="list" gridArea={'C'}>
                    <div className="title"><Link to={`catalog?category=${BuildingMaterials}`} onClick={onClose}>Будівельні матеріали</Link></div>

                    <ul>
                        <li><Link to={`catalog?category=${BuildingMaterials}&subCategory=${Cobblestones}`} onClick={onClose}>Бруківка</Link></li>
                        <li><Link to={`catalog?category=${BuildingMaterials}&subCategory=${Curbs}`} onClick={onClose}>Бордюри</Link></li>
                        <li><Link to={`catalog?category=${BuildingMaterials}&subCategory=${FacadeTiles}`} onClick={onClose}>Плитка Фасадна</Link></li>
                        <li><Link to={`catalog?category=${BuildingMaterials}&subCategory=${PavingTiles}`} onClick={onClose}>Плитка Мощення</Link></li>
                        <li><Link to={`catalog?category=${BuildingMaterials}&subCategory=${Slabs}`} onClick={onClose}>Слеби</Link></li>
                        <li><Link to={`catalog?category=${BuildingMaterials}&subCategory=${CrushedStone}`} onClick={onClose}>Бутовий камінь</Link></li>
                    </ul>
                </div>
                <div className="list" gridArea={'D'}>
                    <div className="title">Інформація</div>
                    <ul>
                        <li><Link to={`sales`} onClick={onClose}>Акції</Link></li>
                        <li><Link to={`delivery-page`} onClick={onClose}>Доставка та встановлення</Link></li>
                        <li><Link to={`type-of-stone`} onClick={onClose}>Види граніту</Link></li>
                        <li><Link to={`cooperation-page`} onClick={onClose}>Співпраця</Link></li>
                        <li><Link to={`catalog?category=${Monuments}&subCategory=${Addition}`} onClick={onClose}>Готові вироби</Link></li>
                        <li><Link to={`production-page`} onClick={onClose}>Про виробництво</Link></li>
                        <li><Link to={`contacts`} onClick={onClose}>Контакти</Link></li>
                    </ul>
                </div>
            </div>
            <div className={`catalog-mobile ${!isOpen ? 'close' : ''}`}>
                <div className="catalog-wrapper">
                    <div className="catalog-logo">
                        <img src='/images/logo.png' width={100} />
                    </div>
                    <div className="home-icon">
                        <a href='/'><HomeOutlined sx={{ color: 'black' }} fontSize='large'></HomeOutlined></a>
                    </div>
                    <div className="catalog-items-wrapper">
                        <div className="title-column">
                            <div className="title" onClick={() => setCategory(Monuments)}>Пам'ятники</div>
                            <div className="title" onClick={() => setCategory(Spotproducts)}>Точені вироби</div>
                            <div className="title" onClick={() => setCategory(GraniteAccessories)}>Гранітні Аксесуари</div>
                            <div className="title" onClick={() => setCategory(Fencing)}>Паркани, огорожі</div>
                            <div className="title" onClick={() => setCategory(HomeDecor)}>Декор для дому</div>
                            <div className="title" onClick={() => setCategory(BuildingMaterials)}>Будівельні матеріали</div>
                            <div className="title" onClick={() => setCategory(MarbleProducts)}>Мармурові вироби</div>
                            <div className="title" onClick={() => setCategory(Info)}>Інформація</div>
                        </div>
                        <div className="list" style={{ display: selectedCategory == Monuments && 'block' }}>
                            <ul>
                                <li><Link to={`catalog?category=${Monuments}&subCategory=${Single}`} onClick={onClose}>Одинарні</Link></li>
                                <li><Link to={`catalog?category=${Monuments}&subCategory=${Double}`} onClick={onClose}>Подвійні</Link></li>
                                <li><Link to={`catalog?category=${Monuments}&subCategory=${Military}`} onClick={onClose}>Військові</Link></li>
                                <li><Link to={`catalog?category=${Monuments}&subCategory=${Family}`} onClick={onClose}>Сімейні</Link></li>
                                <li><Link to={`catalog?category=${Monuments}&subCategory=${Childrens}`} onClick={onClose}>Дитячі</Link></li>
                                <li><Link to={`catalog?category=${Monuments}&subCategory=${ForAnimals}`} onClick={onClose}>Для тварин</Link></li>
                                <li><Link to={`catalog?category=${Monuments}&subCategory=${Exclusive}`} onClick={onClose}>Ексклюзивні</Link></li>
                                <li><Link to={`catalog?category=${Monuments}&subCategory=${Addition}`} onClick={onClose}>Доповнення до пам’ятників</Link></li>
                                <li><Link to={`catalog?category=${Fencing}&subCategory=${ForMonuments}`} onClick={onClose}>Огорожі для пам'ятників</Link></li>
                            </ul>
                        </div>
                        <div className="list" style={{ display: selectedCategory == Spotproducts && 'block' }}>
                            <ul>
                                <li><Link to={`catalog?category=${Spotproducts}&subCategory=${Vases}`} onClick={onClose}>Вази</Link></li>
                                <li><Link to={`catalog?category=${Spotproducts}&subCategory=${Balls}`} onClick={onClose}>Кулі</Link></li>
                                <li><Link to={`catalog?category=${Spotproducts}&subCategory=${Spheres}`} onClick={onClose}>Сфери</Link></li>
                                <li><Link to={`catalog?category=${Spotproducts}&subCategory=${Railings}`} onClick={onClose}>Перила</Link></li>
                                <li><Link to={`catalog?category=${Spotproducts}&subCategory=${Balusters}`} onClick={onClose}>Балясини</Link></li>
                                <li><Link to={`catalog?category=${Spotproducts}&subCategory=${Lampad}`} onClick={onClose}>Лампадки</Link></li>
                            </ul>
                        </div>
                        <div className="list" style={{ display: selectedCategory == GraniteAccessories && 'block' }}>
                            <ul>
                                <li><Link to={`catalog?category=${GraniteAccessories}&subCategory=${Urns}`} onClick={onClose}>Урни для праху</Link></li>
                                <li><Link to={`catalog?category=${GraniteAccessories}&subCategory=${Ashtray}`} onClick={onClose}>Попільнички</Link></li>
                                <li><Link to={`catalog?category=${GraniteAccessories}&subCategory=${NapkinHolder}`} onClick={onClose}>Сарветниця</Link></li>
                                <li><Link to={`catalog?category=${GraniteAccessories}&subCategory=${CuttingBoards}`} onClick={onClose}>Дошки для нарізання</Link></li>
                                <li><Link to={`catalog?category=${GraniteAccessories}&subCategory=${CandyBoxes}`} onClick={onClose}>Цукерниці</Link></li>
                                <li><Link to={`catalog?category=${GraniteAccessories}&subCategory=${SoccerBalls}`} onClick={onClose}>Футбольні м'ячі</Link></li>
                                <li><Link to={`catalog?category=${GraniteAccessories}&subCategory=${Crosses}`} onClick={onClose}>Хрестики</Link></li>
                            </ul>
                        </div>
                        <div className="list" style={{ display: selectedCategory == Fencing && 'block' }}>
                            <ul>
                                <li><Link to={`catalog?category=${Fencing}&subCategory=${Fenc}`} onClick={onClose}>Паркани</Link></li>
                                <li><Link to={`catalog?category=${Fencing}&subCategory=${Gabions}`}>Габіони</Link></li>
                            </ul>
                        </div>
                        <div className="list" style={{ display: selectedCategory == HomeDecor && 'block' }}>
                            <ul>
                                <li><Link to={`catalog?category=${HomeDecor}&subCategory=${Countertops}`} onClick={onClose}>Стільниці</Link></li>
                                <li><Link to={`catalog?category=${HomeDecor}&subCategory=${WindowSills}`} onClick={onClose}>Підвіконня</Link></li>
                                <li><Link to={`catalog?category=${HomeDecor}&subCategory=${Stairs}`} onClick={onClose}>Сходи</Link></li>
                                <li><Link to={`catalog?category=${HomeDecor}&subCategory=${Fountains}`} onClick={onClose}>Фонтани</Link></li>
                                <li><Link to={`catalog?category=${HomeDecor}&subCategory=${Boulders}`} onClick={onClose}>Валуни</Link></li>
                                <li><Link to={`catalog?category=${HomeDecor}&subCategory=${SinksBathtubs}`} onClick={onClose}>Раковини та ванни</Link></li>
                            </ul>
                        </div>
                        <div className="list" style={{ display: selectedCategory == MarbleProducts && 'block' }}>
                            <ul>
                                <li>Скульптури</li>
                            </ul>
                        </div>
                        <div className="list" style={{ display: selectedCategory == BuildingMaterials && 'block' }}>
                            <ul>
                                <li><Link to={`catalog?category=${BuildingMaterials}&subCategory=${Cobblestones}`} onClick={onClose}>Бруківка</Link></li>
                                <li><Link to={`catalog?category=${BuildingMaterials}&subCategory=${Curbs}`} onClick={onClose}>Бордюри</Link></li>
                                <li><Link to={`catalog?category=${BuildingMaterials}&subCategory=${FacadeTiles}`} onClick={onClose}>Плитка Фасадна</Link></li>
                                <li><Link to={`catalog?category=${BuildingMaterials}&subCategory=${PavingTiles}`} onClick={onClose}>Плитка Мощення</Link></li>
                                <li><Link to={`catalog?category=${BuildingMaterials}&subCategory=${Slabs}`} onClick={onClose}>Слеби</Link></li>
                                <li><Link to={`catalog?category=${BuildingMaterials}&subCategory=${CrushedStone}`} onClick={onClose}>Бутовий камінь</Link></li>
                            </ul>
                        </div>
                        <div className="list" style={{ display: selectedCategory == Info && 'block' }}>
                            <ul>
                                <li><Link to={`sales`} onClick={onClose}>Акції</Link></li>
                                <li><Link to={`delivery-page`} onClick={onClose}>Доставка та встановлення</Link></li>
                                <li><Link to={`type-of-stone`} onClick={onClose}>Види граніту</Link></li>
                                <li><Link to={`cooperation-page`} onClick={onClose}>Співпраця</Link></li>
                                <li><Link to={`catalog?category=${Monuments}&subCategory=${Addition}`} onClick={onClose}>Готові вироби</Link></li>
                                <li><Link to={`production-page`} onClick={onClose}>Про виробництво</Link></li>
                                <li><Link to={`contacts`} onClick={onClose}>Контакти</Link></li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        </>

    )
}

export default Catalog;