import React, { useEffect, useState } from 'react';
import './style.scss';
import ProductCart from '../ProductCart/product-cart';
import { CatalogData } from '../../../../data/catalog';
import { CartData } from '../../../../data/cart-content';
import { Slide } from 'react-slideshow-image';
import ImageModal from '../../../../components/Common/ImageModal/main';

const ProductContainer = ({ products, galleryImages, cartData, setCartData }) => {
    const [renderItems, setRenderItems] = useState([]);
    const [indexOfPhoto, setIndexOfPhoto] = useState(-1)

    const AddProductToCart = (object) => {
        debugger
        let index = cartData.findIndex(x => x.id == object.id);
        if (index != -1) {
            var newCart = [...cartData];
            newCart[index].count += 1
            setCartData(newCart)
        } else {
            setCartData(cartData => [...cartData, object]);
        }
    }

    const renderItem = () => {
        let renderProducts = products.map(x => {
            return <><ProductCart id={x.id} urlImg={x.urlImg} price={x.price[0]} subCategory={x.subCategory} code={x.code} sizes={x.sizes} stone={x.stone} addProductToCart={AddProductToCart} /></>
        });
        setRenderItems(renderProducts)
    }

    const onOpenImageModal = (index) => {
        console.log(index)
        setIndexOfPhoto(index)
    }

    const onCloseImageModal = () => {
        setIndexOfPhoto(-1)
    }

    useEffect(() => {
        renderItem()
    }, [products])

    return (
        <>
            <div className="product-container-wrapper">
                <div className="product-container">
                    {renderItems.length == 0 ? <div>У цій категорії немає товарів</div> : renderItems}
                </div>
                {galleryImages.length == 0 ? null :
                    <div className="gallery-wrapper">
                        <h3>Приклади виконаних робіт</h3>
                        <p>На фото зображені реальні вироби, які вже були виконані та встановлені на об'єктах</p>
                        <div className="slider-wrapper pc">
                            <Slide
                                duration={5000}
                                transitionDuration={300}
                                easing="ease"
                                pauseOnHover
                                slidesToScroll={1} slidesToShow={3} indicators={true}>
                                {galleryImages[0].images.map((slideImage, index) => (
                                    <div key={index} id="slider-container" >
                                        <img id="slider-image" src={`${slideImage}`} onClick={() => onOpenImageModal(index)} />
                                    </div>
                                ))}
                            </Slide>
                        </div>
                        <div className="slider-wrapper mobile">
                            <Slide
                                duration={5000}
                                transitionDuration={300}
                                easing="ease"
                                pauseOnHover
                                slidesToShow={1} indicators={true}>
                                {galleryImages[0].images.map((slideImage, index) => (
                                    <div key={index} id="slider-container" >
                                        <img id="slider-image" src={`${slideImage}`} onClick={() => onOpenImageModal(index)} />
                                    </div>
                                ))}
                            </Slide>
                        </div>
                        <ImageModal galleryImages={galleryImages} selectedIndex={indexOfPhoto} onClose={onCloseImageModal}></ImageModal>

                    </div>
                }


            </div>

        </>
    )
}

export default ProductContainer;