import React, { useEffect } from 'react';
import './style.scss';
import CommonLayout from '../../components/Common/Layout/layout';
import { Grid } from '@mui/material';

const CooperationPage = ({ cartData, setCartData }) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <CommonLayout background={'white'}>
                <div className="cooperation-page-container">
                    <h2 className="cooperation-page-title">СПІВПРАЦЯ</h2>
                    <div className="cooperation-page-content">
                        <div className="cooperation-item">
                            <h4>Компанія GranFactory запрошує до співпраці!</h4>
                            <p>
                                GranFactory – каменеобробний завод, який працює з українськими породами каменю. Виробляємо продукцію у двох основних напрямках: будівельні матеріали (внутрішнє та зовнішнє оздоблення, дорожній благоустрій) та ритуальні товари (пам'ятники).
                            </p>
                            <p>Працюємо з партнерами з Грузії, Казахстану, Узбекистану, Молдови, Польщі,Азербайджану.</p>
                            <p>
                                Ми пропонуємо широкий асортимент готової продукції з граніту:
                                <ul className="monument-list">
                                    <li>гранітні пам'ятники</li>
                                    <li>деталі для гранітних пам'ятників</li>
                                    <li>точені вироби</li>
                                    <li>гранітні аксесуари</li>
                                    <li>декор для дому</li>
                                    <li>будівельні матеріали з граніту</li>
                                    <li>мрамурові вироби</li>
                                    <li>плити мощення</li>
                                    <li>сходи та вхідні групи</li>
                                    <li>сляби</li>
                                    <li>бордюри</li>
                                    <li>підвіконня</li>
                                    <li>габіони</li>
                                    <li>фонтани</li>
                                    <li>валуни</li>
                                    <li>раковини та ванни</li>
                                </ul>
                            </p>
                        </div>
                        <div className="cooperation-item">
                            <p><strong>GranFactory</strong> – ваш надійний партнер!</p>
                            <p>Співпрацюючи з GranFactory, ви можете розраховувати на високу якість каменю, акуратність та точність під час виконання робіт. На виробництві використовується найновіше польське та італійське обладнання. Ми отримуємо сировину від з кращих, перевірених роками гранітних кар’єрів та постачальників сировини. Кожна партія каменю перевіряється особисто спеціалістом по контролю якості.</p>

                            <p>Постачаємо великий спектр продукції: починаючи від гранітних блоків та слебів до вже готових виробів. Будматеріали чи ритуальні товари – нам по плечу будь-яке ваше замовлення!</p>

                        </div>
                    </div>
                </div>

            </CommonLayout>
        </>
    )
}

export default CooperationPage;