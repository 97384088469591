import React, { useEffect } from 'react';
import './style.scss';
import CommonLayout from '../../components/Common/Layout/layout';
import { Grid } from '@mui/material';

const ProductionPage = ({ cartData, setCartData }) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <CommonLayout background={'white'}>
                <div className="production-page-container">
                    <h2 className="production-page-title">ПРО ВИРОБНИЦТВО</h2>
                    <div className="production-page-content">
                        <p>
                            Наше підприємство має багаторічний досвід у співпраці з виробниками натурального каменю з найпопулярніших родовищ України, ми пропонуємо широкий асортимент виробів з граніту, габро та лабрадоритів за найкращою ціною на ринку.

                            Ми  співпрацюємо як з індивідуальними замовленнями, так і з великими та дрібними оптовими покупцями. Знаходячись на Житомирщині, де розташовано більше половини родовищ українських гранітів, ми боремося за найкращу конкурентну ціну, обробляючи камінь на місці.
                            Ми маємо значний досвід роботи на ринку та власні виробничі потужності, працюємо з самостійно відібраним природним каменем, тому виготовляємо продукцію, що задовольняє навіть найвибагливіших споживачів. Ми маємо професійно обладнані складські приміщення, спеціальні механізми для вантажнорозвантажувальних операцій та ефективну логістику, що дозволяє нам оперативно постачати продукцію в потрібному обсязі та до визначеної Вами локації.

                        </p>
                        <br />
                        <hr />
                        <br />
                        <p>
                            Щоб отримати якісний продукт, він перед тим має пройти низку змін щодо обробки та зміни його зовнішнього вигляду.
                            Все це відбувається у нас на гранітному підприємстві, де створено всі умови якісного виготовлення бажаного продукту.
                        </p>
                        <br />

                        <strong>Який шлях проходить камінь від цільного блоку до готового виробу?</strong>
                        <p>
                            Спробуємо розібратися
                            Все починається із видобування самого каменю на кар'єрі. Ми самі закуповуємо граніт із кар'єру на виробництво, тому особисто стежимо за якістю сировини, яка використовується при виготовленні продукції.
                            Також ми ретельно обираємо лише найкращі блоки, щоб надалі Ви отримали якісний продукт, оскільки ми цінуємо свою репутацію.
                            Після цього йде доставка матеріалу безпосередньо на саме виробництво, де розпочинається підготовка до роботи з ним.
                            Почнемо від розпилювання самої породи (т.зв. фрезерування).
                        </p>
                        <br />
                        <p>
                            Процес <strong>фрезерування</strong> передбачає обробку заготовок з будь-якого матеріалу інструментом, що обертається - фрезою. Ми поговоримо про обробку одного конкретного матеріалу - каменю, в якому виділяють кілька порід, починаючи від м'якого мармуру і закінчуючи твердим гранітом.
                        </p>
                        <br />
                        <p>
                            Рваний блок гранітного каменю надходить у фрезерувальний цех, де головним інструментом є фрезерний верстат з великою алмазною пилкою. За допомогою навантажувача блок переміщають на робочий стіл верстата під пилку. Потім майстер-фрезерувальник виставляє алмазний диск у положення, що залежать від того, які деталі різатимуться з цього блоку, а точніше - виставляє пилку за певними, заздалегідь зазначеними розмірами.

                            Однак перед цим процесом йде ще процес відкриття блоку - це коли одна сторона зрізається і майстри цеху дивляться, які деталі пам'яті можуть бути отримані з цього блоку - або арки, або тумби, або бордюри квітника, тому різання каменю проводиться тільки з водою і ніяк інакше .
                        </p>
                        <br />
                        <p> Далі, зазвичай, йде <strong>полірування</strong> розрізаних деталей. Це не такий простий процес, як здається на перший погляд. У ході цієї роботи виконується знімання верхнього шару, що, у свою чергу, призводить до відкриття пор матеріалу нижчих шарів. Таким чином, потрібно не тільки досягти поверхні необхідного ступеня глянцю, а й встановити можливість руйнування поверхні.

                            Ця проблема вже давно була вирішена і процес полірування граніту доведено до досконалості. На сьогоднішній день ця технологія складається з кількох послідовних процесів:

                            шліфування;
                            полірування;
                            фінішна обробка.

                            На кожному з них виконується певний вид робіт - у тому випадку виходить дуже красива, ідеально гладка поверхня.
                        </p>
                        <br />

                        <p>При цьому, що дуже важливо, не втрачаються властивості граніту.

                            Важливим етапом полірування цього натурального каменю є шліфування. Ціль цього процесу в тому, щоб вирівняти на поверхні граніту всі нерівності. Процес дуже важливий, але і найбільш жорсткий по відношенню до матеріалу, тому його слід виконувати лише за необхідності. Це у разі того, коли на матеріалі є сколи, вибоїни, зазубрини або якісь нерівності. У процесі знімається верхній шар на глибину самого сколу чи вибоїни. Шліфування дозволить видалити дефекти на поверхнях та перейти до наступного етапу - полірування граніту.

                            Щоб поверхня стала майже дзеркально блискучою, її полірують дрібнозернистими абразивними колами: великі пори затираються, і вся поверхня стає гладкою як скло.
                        </p>
                        <br />
                        <p>
                            Далі, залежно від того, який продукт ми хочемо отримати, вдаються до <strong>ручних робіт</strong>.

                            Тут майстри працюють зі спеціальною технікою, призначеною для створення різних виробів з граніту. За допомогою інструментів вони створюють якісні, красиві, витончені та зносостійкі вироби.
                            Крім меморіальної функції, пам'ятники ще з давніх-давен мали і естетичне призначення. Так скорботні висловлювали свою повагу до пам'яті покійного. Змінювався час, змінювалися і методи обробки каменю. Зараз він доведений майже до досконалості, що дозволяє майстру створити справді дуже гарний виріб.

                            Спочатку воском по граніту він малює форму, за якою працюватиме, а далі вже бере в руки інструмент, це, як правило, болгарка, і з її допомогою створює форму. Внаслідок чого наприкінці ми отримуємо готовий виріб.

                        </p>
                    </div>
                    <Grid container spacing={2} columns={{ xs: 1, sm: 2, md: 2 }}>
                        <Grid item xs={1}>
                            <img src='images/glavna_1.webp' alt='glavna' width={'100%'} />
                        </Grid>
                        <Grid item xs={1}>
                            <img src='images/glavna_2.webp' alt='glavna' width={'100%'} />
                        </Grid>
                    </Grid>
                </div>

            </CommonLayout>
        </>
    )
}

export default ProductionPage;