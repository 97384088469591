import './style.scss';
import { CartData } from '../../../data/cart-content'
import CartRow from './components/cart-item';
import PrimaryButton from '../Button/primary-button';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';

const Cart = ({ isOpen, onClose, cartData, setCartData }) => {
    const [cartItems, setCartItems] = useState([])

    const SummarizePrice = () => {
        let sum = 0;
        cartData.forEach(x => {
            if (typeof (x.price) == typeof (0)) {
                sum += x.price * x.count
            }
        })

        return sum
    }

    const onDeleteItem = (e, id) => {
        e.preventDefault()
        let newArray = [...cartData]
        let index = newArray.findIndex((x) => x.id == id)
        newArray.splice(index, 1);
        setCartData(newArray)
    }

    const renderItems = (itemsArray) => {
        let renderItems = itemsArray.map(x => {
            return <CartRow id={x.id} code={x.code} typeOfStone={x.typeOfStone} urlImg={x.urlImg} name={x.name} size={x.sizes} count={x.count} price={x.price} onDeleteItem={onDeleteItem} />
        })

        return renderItems;
    }

    useEffect(() => {
        setCartItems(renderItems(cartData))
    }, [cartData])

    return (
        <>
            <div className={`cart-background ${!isOpen ? 'close' : ''}`} onClick={onClose}>
            </div>
            <div className={`cart-wrapper ${!isOpen ? 'close' : ''}`}>
                <h3>Корзина</h3>
                {cartData.length == 0 ? <div className='plug'>Кошик порожній</div> :
                    <>
                        <div className="cart-overflow">
                            {cartItems}
                        </div>
                        <div className="button-group">
                            <div>Всього до сплати: <span className='bold-text'>{SummarizePrice()} ₴</span></div>
                            <div onClick={onClose}>
                                <Link to="order-confirm"><PrimaryButton width={150} height={35} text={"Замовити"}></PrimaryButton></Link>
                            </div>
                        </div></>
                }
            </div>
        </>

    )
}

export default Cart;