import React, { useEffect } from 'react';
import './style.scss';
import CommonLayout from '../../components/Common/Layout/layout';
import { Grid } from '@mui/material';

const DeliveryPage = ({ cartData, setCartData }) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <CommonLayout background={'white'}>
                <div className="delivery-page-container">
                    <h2 className="delivery-page-title">ДОСТАВКА ТА ВСТАНОВЛЕННЯ</h2>
                    <div className="delivery-page-content">
                        <h4>ДОСТАВКА ТА ОПЛАТА</h4>
                        <div className='delivery-item'>
                            <p>Можлива доставка до будь-якого регіону України, країн Євросоюзу та інших країн.</p>
                            <p>Ми підбираємо найоптимальніший спосіб, залежно від типу продукції та обсягу замовлення.</p>
                            <p>Доставка по Україні здійснюється наступними способами: за допомогою маніпулятора, «Нової Пошти», «Делівері», попутних вантажів.</p>
                            <p>Доставка за кордон можлива за допомогою контейнерів, автотранспорту, залізничним сполученням тощо.</p>
                        </div>
                        <h4>САМОВИВІЗ</h4>
                        <div className='delivery-item'>
                            <p>
                                Ви можете самостійно забрати замовлення з нашого складу за адресою:<br />
                                <strong>вул.Шухевича (Грибоєдова) 37а,Коростишів,Житомирська область,12500</strong>
                            </p>
                            <p>
                                Графік роботи складу:
                                <strong>ПН-ПТ
                                    8:00-17:00</strong>
                            </p>
                        </div>


                        <h4>ВАРІАНТИ ОПЛАТИ ЗАМОВЛЕННЯ</h4>
                        <div className="delivery-item">
                            <p>Пропонуємо готівковий і безготівковий розрахунок (банківський переказ) на граніт оптом та в роздріб. Залежно від типу природного каменю можлива передоплата, розмір якої обговорюється індивідуально із замовником. Валюта сплачення може бути будь-якою.</p>

                            <p>Фахівці компанії "GranFactory"  виконують монтаж пам’ятників на всій території України. Робота проводиться в стислі терміни, з дотриманням всіх правил і урахуванням місцевих умов. Наші співробітники володіють усіма необхідними знаннями та навичками. Досвід роботи підприємства в цій галузі  дозволяє виконувати роботу швидко і якісно.</p>

                            <p>Для проведення монтажу монумента, наші фахівці найчастіше дотримуються такої схеми:
                                <ul className='monument-list'>
                                    <li>Доставка виробу на кладовище за допомогою власного транспорту.</li>
                                    <li>Якщо потрібно прибрати старий пам’ятник, проводимо його демонтаж, потім готуємо територію і вивозимо все сміття.</li>
                                    <li>Заливаємо фундамент і вичікуємо час, щоб бетон застиг (приблизно 2-3 дні). Далі виконуємо монтаж цоколя з граніту або мармуру.</li>
                                    <li>Встановлення надгробної стели і закріплення за допомогою високоміцного клею.</li>
                                    <li>Формуємо квітник або встановлюємо надгробну плиту.</li>
                                    <li>Якщо в проекті передбачено, укладаємо гранітну плитку навколо могили.</li>
                                    <li>Монтаж огорожі, столиків, лавки і різних декоративних елементів.</li>
                                    <li>Перед тим, як проводити монтаж пам’ятника, фахівці нашої компанії виїжджають на місце і оцінюють умови. У деяких випадках потрібно провести підготовчі роботи.</li>
                                </ul>








                            </p>
                            <p>
                                Якщо, наприклад, грунт на місці установки занадто м’який, багато грунтових вод, необхідно додатково зміцнити фундамент. Проведення такої роботи потрібно також, коли вага пам’ятника велика.
                                Фахівці рекомендують ставити монумент після того, як грунт всядеться. На це може знадобитися до року. Конкретні терміни визначаються особливостями грунту і клімату в місці поховання.
                            </p>
                            <p>Час для монтажу вибирається протягом теплого періоду року, як правило, починаючи з середини травня і закінчуючи серединою жовтня. Замовити виготовлення та оформлення пам’ятника можна заздалегідь: він буде зберігатися в спеціальному приміщенні нашої компанії до моменту монтажу. Термін зберігання не обмежений.
                            </p>
                            <p>
                                У стандартних варіантах установка пам’ятника проводиться протягом двох тижнів, іноді може знадобитися більше часу.
                            </p>

                        </div>

                    </div>
                </div>

            </CommonLayout>
        </>
    )
}

export default DeliveryPage;