import React, { } from 'react';
import './style.scss';
import CommonLayout from '../../components/Common/Layout/layout';

const ContactPage = ({ cartData, setCartData }) => {

    return (
        <>
            <CommonLayout background={'white'}>
                <div className="contact-page-container">
                    <h2 className='contact-page-title'>Контакти</h2>
                    <div className="contact-wrapper">
                        <div className="map">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5095.522680995823!2d29.035989233808632!3d50.315045727372855!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x472c81779839ec97%3A0x2c5934a55e49f2ca!2sgranfactory!5e0!3m2!1suk!2sua!4v1623997534261!5m2!1suk!2sua" allowfullscreen="" loading="lazy"></iframe>
                        </div>
                        <div className="contact-items-container">
                            <div className="contact-item">
                                <div className="contact-item-title">Режим роботи</div>
                                <div className="contact-item-value">Пн-Пт 9:00 - 18:00</div>
                            </div>
                            <div className="contact-item">
                                <div className="contact-item-title">Телефон</div>
                                <div className="contact-item-value">+380 (97) 063 38 39</div>
                            </div>
                            <div className="contact-item">
                                <div className="contact-item-title">Email</div>
                                <div className="contact-item-value">granfactory@gmail.com</div>
                            </div>
                            <div className="contact-item">
                                <div className="contact-item-title">Наше місцезнаходження</div>
                                <div className="contact-item-value">Житомирська обл., м. Коростишів, вул. Грибоєдова, 37а                          </div>
                            </div>
                        </div>
                    </div>
                </div>

            </CommonLayout>
        </>
    )
}

export default ContactPage;